/**
 * Actions Types
 */

export enum GlobalApiTypes {
  FONTES_REQUEST = '@globalApi/FONTE_REQUEST',
  FONTES_SUCCESS = '@globalApi/FONTES_SUCCESS',
  FONTES_LOADING = '@globalApi/FONTES_LOADING',
  FONTES_ERROR = '@globalApi/FONTES_ERROR',

  ESTADOS_REQUEST = '@globalApi/ESTADOS_REQUEST',
  ESTADOS_SUCCESS = '@globalApi/ESTADOS_SUCCESS',
  ESTADOS_LOADING = '@globalApi/ESTADOS_LOADING',
  ESTADOS_ERROR = '@globalApi/ESTADOS_ERROR',

  CIDADES_REQUEST = '@globalApi/CIDADE_REQUEST',
  CIDADES_SUCCESS = '@globalApi/CIDADES_SUCCESS',
  CIDADES_LOADING = '@globalApi/CIDADES_LOADING',
  CIDADES_ERROR = '@globalApi/CIDADES_ERROR',

  UPAGS_REQUESTS = '@globalApi/UPAGS_REQUEST',
  UPAGS_SUCCESS = '@globalApi/UPAGS_SUCCESS',
  UPAGS_LOADING = '@globalApi/UPAGS_LOADING',
  UPAGS_ERROR = '@globalApi/UPAGS_ERROR',

  PROPOSTAS_REQUEST = '@globalApi/PROPOSTAS_REQUEST',
  PROPOSTAS_SUCCESS = '@globalApi/PROPOSTAS_SUCCESS',
  PROPOSTAS_LOADING = '@globalApi/PROPOSTAS_LOADING',
  PROPOSTAS_ERROR = '@globalApi/PROPOSTAS_ERROR',

  CONTRATOS_ATIVOS_REQUEST = '@globalApi/CONTRATOS_ATIVOS_REQUEST',
  CONTRATOS_ATIVOS_SUCCESS = '@globalApi/CONTRATOS_ATIVOS_SUCCESS',
  CONTRATOS_ATIVOS_LOADING = '@globalApi/CONTRATOS_ATIVOS_LOADING',
  CONTRATOS_ATIVOS_ERROR = '@globalApi/CONTRATOS_ATIVOS_ERROR',

  RESET = 'RESET',
}

/**
 * Data Types
 */

export interface Proposta {
  codigoProposta: string
  status: string
  valorProposta: string
  dataSolicitacao: string
  refinanciamento: boolean
}

export interface PropostaApi {
  propostas: Proposta[]
}
export interface ContratosAtivosApi {
  contratos: ContratosAtivos[]
}

export interface ContratosAtivos {
  numeroContrato: string
  valorContrato: number
  proximoVencimento: string
  codigoProduto: string
  modalidade: string
  dataContrato: string
  situacao: StatusContrato
  tipoOperacao: string
}

export type StatusContrato = 'EM_DIA' | 'EM_ATRASO' | 'FECHADO'

export interface FontePagamento {
  codigo: number
  descricao: string
  detalhes: string
}
export interface FontePagamentoApi {
  familiasConvenios: FontePagamento[]
}

export interface Estado {
  uf: string
  nome: string
}
export interface Cidade {
  codigo: string
  nome: string
}
export interface Banco {
  codigo: number
  nome: string
}
export interface Upag {
  codigoOrgao: string
  codigoUpag: string
  nome: string
  sigla: string
}
export interface Convenio {
  codigo: number
  descricao: string
  estado?: string
}
export interface Orgao {
  codigo: number
  descricao: string
}

/**
 * State Type
 */
export interface GlobalApiState {
  readonly fontesPagamento?: FontePagamento[]
  readonly fontesPagamentoError?: boolean
  readonly fontesPagamentoLoading?: boolean

  readonly estados?: Estado[]
  readonly estadosError?: boolean
  readonly estadosLoading?: boolean

  readonly cidades?: Cidade[]
  readonly cidadesError?: boolean
  readonly cidadesLoading?: boolean

  readonly bancos?: Banco[]
  readonly bancosError?: boolean
  readonly bancosLoading?: boolean

  readonly upags: Upag[]
  readonly upagsError?: boolean
  readonly upagsLoading?: boolean

  readonly propostas?: Proposta[]
  readonly propostasError?: boolean
  readonly propostasLoading?: boolean

  readonly contratosAtivos?: ContratosAtivos[]
  readonly contratosAtivosError?: boolean
  readonly contratosAtivosLoading?: boolean
}

/**
 * APIs Type
 */
export interface EstadosAPI {
  uf: string[]
}
export interface CidadesAPI {
  cidades: Cidade[]
}
export interface BancoApi {
  bancos: Banco[]
}
export interface UpagApi {
  upags: Upag[]
}
export interface ConvenioApi {
  convenios: Convenio[]
}
export interface OrgaoApi {
  orgaosPorConvenioConsulta: Orgao[]
}
