import React, { useCallback, useEffect, useRef, useState } from 'react'

import { RemoveAccents } from '@interco/inter-ui-react-lib'
import ChevronDown from '@interco/icons/core/action-navigation/ic_chevron_down'
import Search from '@interco/icons/core/action-navigation/ic_search'
import Error from '@interco/icons/core/status/ic_close_circle_fill'
import { Colors } from '@utils/Colors'
import Paragraph from '@atoms/Paragraph'
import { Loading } from '@atoms/Loading'

import {
  Container,
  InputSelect,
  Label,
  Option,
  HelpButton,
  ContentOptions,
  Overlay,
  SelectArea,
} from './styles'
import { DropdownOption, DropdownProps } from './types'

const Select = ({
  margin,
  name,
  label,
  placeholder,
  value,
  options = [],
  disabled = false,
  hideSearch = false,
  helperButton,
  loading,
  onChange,
  onFilter,
  onClickHelper,
  ...props
}: DropdownProps) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<DropdownOption>(value)
  const [filteredData, setFilteredData] = useState<DropdownOption[]>([])
  const [emptySearch, setEmptySearch] = useState<boolean>(true)
  const searchRef = useRef<HTMLInputElement>(null)

  const setValue = useCallback(() => {
    options.map((item) => (item === value ? setSelected(item) : null))
  }, [options, value, setSelected])

  useEffect(() => {
    setFilteredData(options)

    setValue()
  }, [options, setValue])

  useEffect(() => {
    setValue()
  }, [value, setValue])

  useEffect(() => {
    if (open && searchRef.current) {
      searchRef.current.focus()
    }
  }, [open])

  /**
   * Apaga valor do input de search
   */
  const deleteSearchValue = () => {
    setEmptySearch(true)
    setFilteredData(options)
    if (searchRef.current) {
      searchRef.current.value = ''
    }
  }

  /**
   * Exibir o bottom sheet com as opções somente se o dropdown não estiver desabilitado.
   */
  const showOptions = () => setOpen(!disabled)

  /**
   * Ocultar o bottom sheet com as opções.
   */
  const hideOptions = () => {
    setOpen(false)
    deleteSearchValue()
  }

  /**
   * Evento disparado ao clicar em uma das opções exibidas no bottom sheet.
   * @param item Item selecionado.
   */
  const changeOption = (item: DropdownOption) => {
    setSelected(item)
    onChange(item)
    hideOptions()
  }

  /**
   * Filtro default para o input search.
   * @param search Dado informado no input.
   * @param item Opção do dropdown para realizar a comparação.
   */
  const onFilterDefault = (search: string, item: DropdownOption) =>
    RemoveAccents(item.text).includes(search)

  /**
   * Evento disparado ao digitar informações no search para filtrar.
   * @param event KeyboardEvent disparado.
   */
  const keyUpSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const valueSearch = (event.target as HTMLInputElement).value

    const search = RemoveAccents(valueSearch)
    const filter = onFilter || onFilterDefault

    setEmptySearch(!valueSearch)

    setFilteredData(options.filter((item, index) => filter(search, item, index)))
  }

  /**
   * Renderização da lista de opções para o bottom sheet.
   */
  const renderOptions = filteredData.map((item) => (
    <Option key={`dropdown-${item.value}`} role="option" onClick={() => changeOption(item)}>
      <Paragraph fontSize="14px" lineHeight="17px" color={Colors.GRAY400} margin="0">
        {item.text}
      </Paragraph>
    </Option>
  ))

  return (
    <Container margin={margin} {...props}>
      <Label id={name} disabled={disabled} useHelperButton={!!helperButton}>
        {label}

        {helperButton && (
          <HelpButton type="button" onClick={onClickHelper}>
            {helperButton}
          </HelpButton>
        )}
      </Label>
      <InputSelect
        onClick={showOptions}
        disabled={disabled}
        placeholder={!selected?.text ? placeholder : undefined}
        role="listbox"
        aria-labelledby={name}
        aria-disabled={disabled}
        aria-expanded={open}
      >
        <p>{selected?.text || placeholder}</p>
        {loading ? (
          <Loading />
        ) : (
          <ChevronDown
            width={24}
            height={24}
            color={disabled ? Colors.GRAY200 : Colors.PRIMARY500}
          />
        )}
      </InputSelect>
      {open && (
        <>
          <Overlay open={open} onClick={hideOptions} />
          <SelectArea>
            {!hideSearch && (
              <div className="group-search">
                {emptySearch && (
                  <Search
                    width={16}
                    height={16}
                    color={Colors.PRIMARY500}
                    className="inter-ic-search"
                  />
                )}

                <input
                  ref={searchRef}
                  type="search"
                  name="dropdown-search"
                  placeholder="Pesquisar"
                  autoComplete="off"
                  onKeyUp={keyUpSearch}
                  aria-labelledby={name}
                />

                {!emptySearch && (
                  <Error
                    onClick={deleteSearchValue}
                    width={16}
                    height={16}
                    color={Colors.PRIMARY500}
                    className="inter-ic-contextual-error"
                  />
                )}
              </div>
            )}
            <ContentOptions>{renderOptions}</ContentOptions>
          </SelectArea>
        </>
      )}
    </Container>
  )
}

export default Select
