import { BaseServices, HttpMethod } from '@services/base'

export default (codigoProposta: string, tipoAutenticacao: string, token: string, valor: string) => {
  const pathname = process.env.REACT_APP_CONTRATO_SANGUE_LARANJA_SALVAR_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const data = {
    codigoProposta,
    tipoAutenticacao,
    token,
    valor,
  }

  return BaseServices.request({
    pathname,
    method,
    data,
    host,
  })
}
