import { put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'

import { postSalvarContratoSangueLaranja, postSalvarContratoSangueLaranjaSuccess } from '../actions'

export default function* postSalvarContratoSangueLaranjaSaga(action: AnyAction) {
  const payload = action.payload as PayloadSalvarContrato

  try {
    yield services.postSalvarContratoSangueLaranja(
      payload.codigoProposta,
      payload.tipoAutenticacao,
      payload.token,
      payload.valor,
    )

    yield put(postSalvarContratoSangueLaranjaSuccess())
    yield payload.callback()
  } catch (error) {
    yield put(
      showError({
        title: 'Erro ao salvar contrato sangue laranja',
        message: 'Ocorreu um erro ao salvar contrato sangue laranja.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => postSalvarContratoSangueLaranja(action.payload),
      }),
    )
  }
}

export interface PayloadSalvarContrato {
  callback: () => void
  codigoProposta: string
  tipoAutenticacao: string
  token: string
  valor: string
}

export interface PayloadExcluirProposta {
  callback: () => void
  codigoProposta: string
}
