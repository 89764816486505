import React, { Suspense } from 'react'

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useSelector } from 'react-redux'

import HeaderAndFooter from '@components/templates/HeaderAndFooter'
import { GoBackConfig } from '@utils/goBackConfig'
import { BridgeService } from '@services/bridge'
import ContratoModule from '@interco/cp-contratos-ui-lib'
import { ApplicationState } from '@store/types'
import FallbackPage from '@components/organims/FallbackPage'

import Privado from './Privado'
import routes from './routes'
import SimuladorNovo from './SimuladorNovo'
import SimuladorApp from './SimuladorApp'
import SimuladorV3 from './SimuladorV3'

const Routes: React.FC = () => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { numeroContrato } = useSelector((state: ApplicationState) => state.novo.dadosPessoais)

  return (
    <>
      {BridgeService.isBrowser() ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={String(process.env.REACT_APP_RECAPTCHA_SITE_KEY)}
          useEnterprise
        >
          <BrowserRouter>
            <GoBackConfig />
            <HeaderAndFooter>
              <Switch>
                <Route path={routes.NOVO} component={Privado} />
              </Switch>
              <Switch>
                <Route path={routes.SIMULADOR_NOVO} component={SimuladorNovo} />
              </Switch>
              <Switch>
                <Route path={routes.SIMULADOR_APP} component={SimuladorApp} />
              </Switch>
              <Switch>
                <Route path={routes.SIMULADOR_V3} component={SimuladorV3} />
              </Switch>
            </HeaderAndFooter>
            <Switch>
              <Suspense fallback={<FallbackPage />}>
                <Route path="/contratos">
                  <ContratoModule
                    isApp={isApp}
                    apiHost={process.env.REACT_APP_HOST_CONSIGNADO}
                    environment={process.env.REACT_APP_ENVIROMENT}
                    numeroContrato={numeroContrato}
                  />
                </Route>
              </Suspense>
            </Switch>
          </BrowserRouter>
        </GoogleReCaptchaProvider>
      ) : (
        <BrowserRouter>
          <GoBackConfig />
          <Switch>
            <Route path={routes.NOVO} component={Privado} />
          </Switch>
          <Switch>
            <Route path={routes.SIMULADOR_NOVO} component={SimuladorNovo} />
          </Switch>
          <Switch>
            <Route path={routes.SIMULADOR_APP} component={SimuladorApp} />
          </Switch>
          <Switch>
            <Route path={routes.SIMULADOR_V3} component={SimuladorV3} />
          </Switch>
          <Switch>
            <Suspense fallback={<FallbackPage />}>
              <Route path="/contratos">
                <ContratoModule
                  isApp={isApp}
                  apiHost={process.env.REACT_APP_HOST_CONSIGNADO}
                  environment={process.env.REACT_APP_ENVIROMENT}
                  numeroContrato={numeroContrato}
                />
              </Route>
            </Suspense>
          </Switch>
        </BrowserRouter>
      )}
    </>
  )
}
export default Routes
