import React, { memo } from 'react'

import { formatCurrency } from '@utils/masks'
import Edit from '@interco/icons/core/action-navigation/ic_edit'

import { Container, Input } from './styles'
import Props from './types'

const InputPrice = ({
  id,
  placeholder,
  value,
  onChangeInput,
  dataTestId,
  onBlur,
  hasEditIcon = false,
  colorInput,
  fontSize,
  fontWeight,
}: Props) => (
  <Container hasEditIcon={hasEditIcon}>
    <Input
      placeholder={placeholder}
      id={id}
      name={id}
      value={formatCurrency(value)}
      onChange={onChangeInput}
      onBlur={onBlur}
      data-testid={`input-${dataTestId}`}
      colorInput={colorInput}
      fontSize={fontSize}
      fontWeight={fontWeight}
      inputMode="numeric"
    />
    {hasEditIcon && <Edit width={24} height={24} style={{ marginLeft: '10px' }} />}
  </Container>
)

export default memo(InputPrice)
